/*
 * Zkopirovano z app.js
 */
/* odkonentovat pri buildu */
/*window.$ = window.jQuery = require('jquery')
 require('bootstrap');
 //require('bootstrap-sass');
 require('select2');
 */
require('bootstrap-validator');
/*require('blueimp-gallery');
 /*require('block-ui'); */

$(document).ready(function () {
    //console.log($.fn.tooltip.Constructor.VERSION);
    // var window_width = $(window).width();
    // console.log(window_width);
    //$("#window_width").html(window_width);

    if ($(window).width() < 1000) { //767 - je moc malo kvuli gridu..
        $('.container.main-form #collapseForm, .container-fluid.main-form #collapseForm').each(function () {
            $(this).addClass('in'); // default je zavrene, pri refresi pod small device otevrene(zmizi helper form)
        })
    }

    /*$(".navbar-toggle").click(function(event) {

     $(".navbar-collapse").toggle('in');
     $(".navbar-collapse").removeClass('in');
     $(this).collapse('hide');
     //alert("hide");
     });*/



    // panely na vyhody na landing page a detailu CVV (pri pozjeti odscolluje na zacatek bloku)
    $('#accordion').on('shown.bs.collapse', function () {
        var panel = $(this).find('.in');

        $('html, body').animate({
            scrollTop: panel.offset().top - 90
        }, 500);
    });




    $('#contact-form').validator();

    $('input[id="email"]').change(function () {
        if (!$(this).parents().hasClass("has-error") && $(this).val().length > 0) {
            $('input[id="phone"]').removeAttr('required');
        }
    });

    $('input[id="phone"]').change(function () {
        if (!$(this).parents().hasClass("has-error") && $(this).val().length > 0) {
            $('input[id="email"]').removeAttr('required');
        }
    });


    // event pri modal window sendmailu
    $('a.sendmail').on('click', function () {
        // Display a ajax modal sendmail fnc
        var a_href = $(this).attr('href');
        var options = {
            url: a_href,
            title: 'Poslat odkaz na vůz emailem',
            buttons: [
                { text: 'Odeslat', style: 'info', close: false, click: sendMail },
                { text: 'Zavřít', style: 'danger', close: true }
            ]
        };

        eModal.ajax(options);

        return false;
    });

    function sendMail() {
        $('#sendmail').validator();
        $("#sendmail").submit();
    }

    /*
     ZPETNY ODKUP
     */
    if ($("body.repurchase").length) {
        if ($("body.repurchase #desktop-form").offset().top > 0) {
            $('body.repurchase #desktop-form').validator();
            // console.log("desktop");
        } else {
            $('body.repurchase #mobile-form').validator();
            // console.log("mobil");
        }
    }

    /*
      ZPETNY ODKUP - vykup-vozu-promo-c3
      */
    if ($("body.repurchase-promo-c3").length) {
        if ($("body.repurchase-promo-c3 #desktop-form").offset().top > 0) {
            $('body.repurchase-promo-c3 #desktop-form').validator();
            // console.log("desktop");
        } else {
            $('body.repurchase-promo-c3 #mobile-form').validator();
            // console.log("mobil");
        }
    }

    /*
     CAROUSEL
     */
    var clickEvent = false;
    $('#cvv-carousel').on('click', '#cvv-carousel .nav a', function () {
        /*clickEvent = true;
         $('#cvv-carousel .nav li').removeClass('active');
         $(this).parents().find('li').addClass('active');

         console.log($(this).parent());*/
        /*$('#cvv-carousel').carousel('pause');
         console.log("pausa1");*/
    }).on('slid.bs.carousel', function (e) {
        $('#cvv-carousel .nav a').on('click', function () {
            $('#cvv-carousel .nav li').removeClass('active');
            
            $(this).parent().addClass('active');

            clickEvent = true;
            
            $('#cvv-carousel').carousel('pause');

            // console.log("pausa2");
        });

        if (!clickEvent) {
            var count = $('#cvv-carousel .nav').children().length - 1;
            var current = $('#cvv-carousel .nav li.active');
            
            current.removeClass('active').next().addClass('active');
            current.removeClass('active');
            
            var id = parseInt(current.data('slide-to'));

            if (count == id) {
                $('#cvv-carousel .nav li').first().addClass('active');
            }
            //clickEvent = true;

        }
        clickEvent = false;
    });


    if ($("#dealer_message").length) {
        // textarea poznamka z vypoctem zbylych znaku
        var text_max = 1000;

        $('#dealer_message').keyup(function () {
            var text_length = mb_strlen($(this).val());
            var text_remaining = text_max - text_length;

            $('.count_message').html(text_remaining + ' zbývá');
        });
    }
}); // END domready

// vypocet pro pocet znaku ve stringu dle charakteru, tzn diakrika ma nektere znaky za 2 proti normal .lenght
function mb_strlen(str) {
    var len = 0;
    
    for (var i = 0; i < str.length; i++) {
        len += str.charCodeAt(i) < 0 || str.charCodeAt(i) > 255 ? 2 : 1;
    }

    return len;
}
/****************
 * COMMON INIT
 ***************/

// nesmi bejt na onready kdyz se ajaxove donacitaji radky
$(document).on('click', '.row-car', function () {
    window.document.location = $(this).data("url");
});

jQuery(document).ready(function () {
    $("a.scroll").click(function () {
        var targetDiv = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(targetDiv).offset().top
        }, 'slow');
    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    $(function () {
        $('[data-toggle="popover"]').popover()
    })

    $('.form-top [data-toggle=collapse]').click(function () {
        $(this).find("span").toggleClass("glyphicon-chevron-down glyphicon-chevron-up"); // toggle icon
    });


    // proklik primo z menu, pokud je ale mobil tak nezadouci, kvuli dropdownu
    $('#navbar').on('hide.bs.collapse ', function () {
        $("a#cvv-link").click(function () {
            window.document.location = $(this).attr("href");
        });
    });

    $('.carousel').bcSwipe({ threshold: 50 })

    $("select").select2({
        language: "cs",
        width: '100%',
        allowClear: false,
        minimumResultsForSearch: Infinity
    });

    $('.select2--default').select2({
        width: '100%'
    }); // default

    // hover na dropdown menu - dela blbosti u mobilu
    /*$(".navbar .dropdown-menu").hover(function() {       
      
      $(this).parent().css("background-color","#fff");
      $(this).parent().css("color","#000");
      //alert("hover");
      
    },
     function(){
      
      alert("pryc");
      $(this).parent().css("background-color","transparent");
      
    });*/

    jQuery(function ($) {
        if ($(window).width() > 769) {
            /*    $('.navbar .dropdown').hover(function() {
                    $(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();
        
                }, function() {
                    $(this).find('.dropdown-menu').first().stop(true, true).delay(150).slideUp();
        
                });
        */
            $('.navbar .dropdown > a').click(function () {
                location.href = this.href;
            });
        }
    });

    /*$('#navbar').on('show', function (e) {
      
            // hide open menus
            $('.collapse').each(function(){
          if ($(this).hasClass('in')) {
              $(this).collapse('toggle');
          }
        });
      
    })*/
}); //end init
